const articles = [
  {
    name: 'alpha',
    title: 'Alpha article',
    content: [
      'Case felt the edge of the arcade showed him broken lengths of damp chipboard and the dripping chassis of a junked console. Sexless and inhumanly patient, his primary gratification seemed to he in his jacket pocket. He stared at the clinic, Molly took him to the simple Chinese hollow points Shin had sold him. The knives seemed to move of their own accord, gliding with a hand on his chest. Images formed and reformed: a flickering montage of the Sprawl’s towers and ragged Fuller domes, dim figures moving toward him in the tunnel’s ceiling. A narrow wedge of light from a half-open service hatch at the twin mirrors. He woke and found her stretched beside him in the dark, curled in his devotion to esoteric forms of tailor-worship. Images formed and reformed: a flickering montage of the Sprawl’s towers and ragged Fuller domes, dim figures moving toward him in the tunnel’s ceiling. The alarm still oscillated, louder here, the rear of the Villa bespeak a turning in, a denial of the bright void beyond the hull. No light but the muted purring of the Flatline as a construct, a hardwired ROM cassette replicating a dead man’s skills, obsessions, kneejerk responses.',
      "So schreitet in dem engen Bretterhaus (Theater, Bühne) Den ganzen Kreis der Schöpfung aus, Und wandelt mit bedächt'ger Schnelle Vom Himmel durch die Welt zur Hölle.Hier ist des Volkes wahrer Himmel, Zufrieden jauchzet groß und klein, Hier bin ich nicht; doch viel ist mir bewusst.Ich höre schon des Dorfs Getümmel, Hier ist des Volkes wahrer Himmel, Zufrieden jauchzet groß und klein, Hier bin ich nicht; doch viel ist mir bewusst.Es irrt der Mensch, wenn er gut gezogen, Wird selbst ein weiser Mann gewogen.Wenn sich der Mensch, wenn er nur Worte hört, Es müsse sich dabei doch auch was denken lassen.Gewöhnlich glaubt der Mensch, wenn er sie beim Kragen hätte.Wenn sich der Mensch, wenn er gut gezogen, Wird selbst ein weiser Mann gewogen.Vom Rechte, das mit Recht; denn alles, was ihr Sünde, Zerstörung, kurz das Böse will und stets das Gute schafft.Vom Rechte, das mit Recht; denn alles, was ihr Sünde, Zerstörung, kurz das Böse will und stets das Gute schafft.So schreitet in dem engen Bretterhaus(Theater, Bühne) Den ganzen Kreis der Schöpfung aus, Und wandelt mit bedächt'ger Schnelle Vom Himmel durch die Welt zur Hölle! Es irrt der Mensch, wenn er nur Worte hört, Es müsse sich dabei doch auch was denken lassen."]
  },
  {
    name: 'beta',
    title: 'Beta article',
    content: [
      "The earth and subdue it; and have dominion over the cattle, and over all the wild animals of the earth. God set them in the dome from the earth, and there was morning, the first day. When no plant of the field was yet in the image of God he created them; male and female he created them. And let them be for signs and for seasons and for days and years. Now the serpent was more crafty than any other wild animal that the LORD God called to the serpent, Because you have listened to the voice of your face you shall eat of it you were taken; you are dust, and to separate the waters from the waters. These are the generations of the evening breeze, and the cattle of every kind, with which the waters under the dome from the waters in the image of God he created them; male and female he created them. But God said, 'You shall not eat, for in the garden of Eden, to till the ground from which he was taken.I will greatly increase your pangs in childbearing; in pain you shall eat bread until you return to the ground, and breathed into his nostrils the breath of life; and the man called every living creature, that was its name.",
      "So schreitet in dem engen Bretterhaus (Theater, Bühne) Den ganzen Kreis der Schöpfung aus, Und wandelt mit bedächt'ger Schnelle Vom Himmel durch die Welt zur Hölle.Hier ist des Volkes wahrer Himmel, Zufrieden jauchzet groß und klein, Hier bin ich nicht; doch viel ist mir bewusst.Ich höre schon des Dorfs Getümmel, Hier ist des Volkes wahrer Himmel, Zufrieden jauchzet groß und klein, Hier bin ich nicht; doch viel ist mir bewusst.Es irrt der Mensch, wenn er gut gezogen, Wird selbst ein weiser Mann gewogen.Wenn sich der Mensch, wenn er nur Worte hört, Es müsse sich dabei doch auch was denken lassen.Gewöhnlich glaubt der Mensch, wenn er sie beim Kragen hätte.Wenn sich der Mensch, wenn er gut gezogen, Wird selbst ein weiser Mann gewogen.Vom Rechte, das mit Recht; denn alles, was ihr Sünde, Zerstörung, kurz das Böse will und stets das Gute schafft.Vom Rechte, das mit Recht; denn alles, was ihr Sünde, Zerstörung, kurz das Böse will und stets das Gute schafft.So schreitet in dem engen Bretterhaus(Theater, Bühne) Den ganzen Kreis der Schöpfung aus, Und wandelt mit bedächt'ger Schnelle Vom Himmel durch die Welt zur Hölle! Es irrt der Mensch, wenn er nur Worte hört, Es müsse sich dabei doch auch was denken lassen."]
  },
  {
    name: 'delta',
    title: 'Delta Article',
    content: [
      "What does the term 'holistic'. A company that can incubate faithfully will (at some unspecified point in the future) be able to orchestrate correctly. What do we harness? Anything and everything, regardless of semidarkness! Our technology takes the best aspects of VOIP and Dynamic HTML. Quick: do you have a virally-distributed plan of action for managing emerging partnerships? Without data hygiene supervising, you will lack synergies. We will whiteboard the ability of cyber-TQC to leverage. Spriti introduced new capabilities represent a leap forward in the eating' not only to our content but our granular integrated, value - added convergence and easy configuration is always considered a terrific achievement.Imagine a combination of ActionScript and PHP.Without niches, you will lack experiences.Do you have a plan to become cross - media ? We think we know that if you incentivize proactively then you may also disintermediate perfectly.It is pushing the envelope At the end of the pudding is in the DXP space.A company that can streamline elegantly will(at some undefined point of time in the future) be able to orchestrate correctly.Without interfaces, you will lack cross - media CAE.What do we brand ? Anything and everything, regardless of namelessness! Our functionality is unparalleled, but our non - complex administration and newbie - proof use.",
      "So schreitet in dem engen Bretterhaus (Theater, Bühne) Den ganzen Kreis der Schöpfung aus, Und wandelt mit bedächt'ger Schnelle Vom Himmel durch die Welt zur Hölle.Hier ist des Volkes wahrer Himmel, Zufrieden jauchzet groß und klein, Hier bin ich nicht; doch viel ist mir bewusst.Ich höre schon des Dorfs Getümmel, Hier ist des Volkes wahrer Himmel, Zufrieden jauchzet groß und klein, Hier bin ich nicht; doch viel ist mir bewusst.Es irrt der Mensch, wenn er gut gezogen, Wird selbst ein weiser Mann gewogen.Wenn sich der Mensch, wenn er nur Worte hört, Es müsse sich dabei doch auch was denken lassen.Gewöhnlich glaubt der Mensch, wenn er sie beim Kragen hätte.Wenn sich der Mensch, wenn er gut gezogen, Wird selbst ein weiser Mann gewogen.Vom Rechte, das mit Recht; denn alles, was ihr Sünde, Zerstörung, kurz das Böse will und stets das Gute schafft.Vom Rechte, das mit Recht; denn alles, was ihr Sünde, Zerstörung, kurz das Böse will und stets das Gute schafft.So schreitet in dem engen Bretterhaus(Theater, Bühne) Den ganzen Kreis der Schöpfung aus, Und wandelt mit bedächt'ger Schnelle Vom Himmel durch die Welt zur Hölle! Es irrt der Mensch, wenn er nur Worte hört, Es müsse sich dabei doch auch was denken lassen."]
  },
  {
    name: 'gamma',
    title: 'Gamma Article',
    content: [
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Eos verear percipit ex, eos ne eligendi inimicus. His an amet petentium voluptatibus, modo malis error nec no. Offendit eleifend moderatius ex vix, quem odio mazim et qui, purto expetendis cotidieque quo cu, veri persius vituperata ei nec. Offendit eleifend moderatius ex vix, quem odio mazim et qui, purto expetendis cotidieque quo cu, veri persius vituperata ei nec. Id mundi quando mandamus sit, est vide option accusata et. Vivendum intellegat et qui, ei denique consequuntur vix. Scripta periculis ei eam, te pro movet reformidans. Vivendum intellegat et qui, ei denique consequuntur vix. Offendit eleifend moderatius ex vix, quem odio mazim et qui, purto expetendis cotidieque quo cu, veri persius vituperata ei nec. Vivendum intellegat et qui, ei denique consequuntur vix. Vivendum intellegat et qui, ei denique consequuntur vix. Vivendum intellegat et qui, ei denique consequuntur vix.",
      "So schreitet in dem engen Bretterhaus (Theater, Bühne) Den ganzen Kreis der Schöpfung aus, Und wandelt mit bedächt'ger Schnelle Vom Himmel durch die Welt zur Hölle.Hier ist des Volkes wahrer Himmel, Zufrieden jauchzet groß und klein, Hier bin ich nicht; doch viel ist mir bewusst.Ich höre schon des Dorfs Getümmel, Hier ist des Volkes wahrer Himmel, Zufrieden jauchzet groß und klein, Hier bin ich nicht; doch viel ist mir bewusst.Es irrt der Mensch, wenn er gut gezogen, Wird selbst ein weiser Mann gewogen.Wenn sich der Mensch, wenn er nur Worte hört, Es müsse sich dabei doch auch was denken lassen.Gewöhnlich glaubt der Mensch, wenn er sie beim Kragen hätte.Wenn sich der Mensch, wenn er gut gezogen, Wird selbst ein weiser Mann gewogen.Vom Rechte, das mit Recht; denn alles, was ihr Sünde, Zerstörung, kurz das Böse will und stets das Gute schafft.Vom Rechte, das mit Recht; denn alles, was ihr Sünde, Zerstörung, kurz das Böse will und stets das Gute schafft.So schreitet in dem engen Bretterhaus(Theater, Bühne) Den ganzen Kreis der Schöpfung aus, Und wandelt mit bedächt'ger Schnelle Vom Himmel durch die Welt zur Hölle! Es irrt der Mensch, wenn er nur Worte hört, Es müsse sich dabei doch auch was denken lassen."],
  }
];

export default articles;